<div class="post-image-grid">
  <!-- One media case -->
  <ng-container *ngIf="media.length === 1">
    <div class="container-single-image" [class.portrait]="isFirstTall" [class.landscape]="!isFirstTall">
      <ng-container *ngIf="!autoplay">
        <img *ngIf="media[0].preview || media[0].path" [ngSrc]="media[0].preview ? media[0].preview! : media[0].path!" [width]="media[0].width" [height]="media[0].height" [sizes]="'100vw'" [alt]="media[0].filename" priority />
      </ng-container>
      <ng-container *ngIf="autoplay">
        <video autoplay loop [muted]="true">
          <source [fileName]="media[0].path" type="video/mp4" src="" appVideoSrc />
          <img *ngIf="media[0].preview || media[0].path" [ngSrc]="media[0].preview ? media[0].preview! : media[0].path!" [width]="media[0].width" [height]="media[0].height" [sizes]="'100vw'" [alt]="media[0].filename" priority />
        </video>
      </ng-container>
    </div>
  </ng-container>

  <!-- Two media case -->
  <ng-container *ngIf="media.length === 2">
    <div class="container-two-images" [class.portrait]="isFirstTall">
      <div class="img-wrapper">
        <img *ngIf="media[0].preview || media[0].path" [ngSrc]="media[0].preview ? media[0].preview! : media[0].path!" [width]="media[0].width" [height]="media[0].height" [sizes]="'100vw'" [alt]="media[0].filename" priority/>
      </div>
      <div class="img-wrapper">
        <img *ngIf="media[1].preview || media[1].path" [ngSrc]="media[1].preview ? media[1].preview! : media[1].path!" [width]="media[1].width" [height]="media[1].height" [sizes]="'100vw'" [alt]="media[1].filename" priority/>
      </div>
    </div>
  </ng-container>

  <!-- Three media case -->
  <ng-container *ngIf="media.length === 3">
    <div *ngIf="isFirstTall" class="container-three-images" [class.first-portrait]="isFirstTall" [class.first-landscape]="!isFirstTall">
      <!-- First Image (Left Column or Top Row) -->
      <div class="img-wrapper portrait">
        <img *ngIf="media[0].preview || media[0].path" [ngSrc]="media[0].preview ? media[0].preview! : media[0].path!" [width]="media[0].width" [height]="media[0].height" [sizes]="'100vw'" [alt]="media[0].filename" priority />
      </div>

      <div *ngIf="!isFirstTall" class="img-wrapper landscape">
        <img *ngIf="media[0].preview || media[0].path" [ngSrc]="media[0].preview ? media[0].preview! : media[0].path!" [width]="media[0].width" [height]="media[0].height" [sizes]="'100vw'" [alt]="media[0].filename" priority />
      </div>

      <!-- Second & Third Images -->
      <div class="img-group">
        <div class="img-wrapper second">
          <img *ngIf="media[1].preview || media[1].path" [ngSrc]="media[1].preview ? media[1].preview! : media[1].path!" [width]="media[1].width" [height]="media[1].height" [sizes]="'100vw'" [alt]="media[1].filename" priority />
        </div>
        <div class="img-wrapper third">
          <img *ngIf="media[2].preview || media[2].path" [ngSrc]="media[2].preview ? media[2].preview! : media[2].path!" [width]="media[2].width" [height]="media[2].height" [sizes]="'100vw'" [alt]="media[2].filename" priority />
        </div>
      </div>
    </div>
  </ng-container>

  <!-- Four media case -->
  <ng-container *ngIf="media.length === 4">
    <div class="container-four-images" [class.first-portrait]="isFirstTall" [class.first-landscape]="!isFirstTall">
      
      <!-- First Image (Portrait: Left Column, Landscape: Top Row) -->
      <div class="img-wrapper portrait" *ngIf="isFirstTall">
        <img *ngIf="media[0].preview || media[0].path" [ngSrc]="media[0].preview ? media[0].preview! : media[0].path!" [width]="media[0].width" [height]="media[0].height" [sizes]="'100vw'" [alt]="media[0].filename" priority />
      </div>

      <div class="img-wrapper landscape" *ngIf="!isFirstTall">
        <img *ngIf="media[0].preview || media[0].path" [ngSrc]="media[0].preview ? media[0].preview! : media[0].path!" [width]="media[0].width" [height]="media[0].height" [sizes]="'100vw'" [alt]="media[0].filename" priority />
      </div>

      <!-- Right Column for Portrait (Stacked) -->
      <div class="img-group" *ngIf="isFirstTall">
        <div class="img-wrapper top">
          <img *ngIf="media[1].preview || media[1].path" [ngSrc]="media[1].preview ? media[1].preview! : media[1].path!" [width]="media[1].width" [height]="media[1].height" [sizes]="'100vw'" [alt]="media[1].filename" priority />
        </div>
        <div class="bottom-group">
          <div class="img-wrapper">
            <img *ngIf="media[2].preview || media[2].path" [ngSrc]="media[2].preview ? media[2].preview! : media[2].path!" [width]="media[2].width" [height]="media[2].height" [sizes]="'100vw'" [alt]="media[2].filename" priority />
          </div>
          <div class="img-wrapper">
            <img *ngIf="media[3].preview || media[3].path" [ngSrc]="media[3].preview ? media[3].preview! : media[3].path!" [width]="media[3].width" [height]="media[3].height" [sizes]="'100vw'" [alt]="media[3].filename" priority />
          </div>
        </div>
      </div>

      <!-- Bottom Row for Landscape (3 Equal Columns) -->
      <div class="img-group" *ngIf="!isFirstTall">
        <div class="img-wrapper">
          <img *ngIf="media[1].preview || media[1].path" [ngSrc]="media[1].preview ? media[1].preview! : media[1].path!" [width]="media[1].width" [height]="media[1].height" [sizes]="'100vw'" [alt]="media[1].filename" priority />
        </div>
        <div class="img-wrapper">
          <img *ngIf="media[2].preview || media[2].path" [ngSrc]="media[2].preview ? media[2].preview! : media[2].path!" [width]="media[2].width" [height]="media[2].height" [sizes]="'100vw'" [alt]="media[2].filename" priority />
        </div>
        <div class="img-wrapper">
          <img *ngIf="media[3].preview || media[3].path" [ngSrc]="media[3].preview ? media[3].preview! : media[3].path!" [width]="media[3].width" [height]="media[3].height" [sizes]="'100vw'" [alt]="media[3].filename" priority />
        </div>
      </div>

    </div>
  </ng-container>

  <!-- Five media case -->
  <ng-container *ngIf="media.length === 5">
    <div class="container-five-images">
      
      <!-- Top Row (Two Images, 60% Height) -->
      <div class="top-row">
        <div class="img-wrapper">
          <img *ngIf="media[0].preview || media[0].path" [ngSrc]="media[0].preview ? media[0].preview! : media[0].path!" [width]="media[0].width" [height]="media[0].height" [sizes]="'100vw'" [alt]="media[0].filename" priority/>
        </div>
        <div class="img-wrapper">
          <img *ngIf="media[1].preview || media[1].path" [ngSrc]="media[1].preview ? media[1].preview! : media[1].path!" [width]="media[1].width" [height]="media[1].height" [sizes]="'100vw'" [alt]="media[1].filename" priority/>
        </div>
      </div>

      <!-- Bottom Row (Three Images, 40% Height) -->
      <div class="bottom-row">
        <div class="img-wrapper">
          <img *ngIf="media[2].preview || media[2].path" [ngSrc]="media[2].preview ? media[2].preview! : media[2].path!" [width]="media[2].width" [height]="media[2].height" [sizes]="'100vw'" [alt]="media[2].filename" priority/>
        </div>
        <div class="img-wrapper">
          <img *ngIf="media[3].preview || media[3].path" [ngSrc]="media[3].preview ? media[3].preview! : media[3].path!" [width]="media[3].width" [height]="media[3].height" [sizes]="'100vw'" [alt]="media[3].filename" priority/>
        </div>
        <div class="img-wrapper">
          <img *ngIf="media[4].preview || media[4].path" [ngSrc]="media[4].preview ? media[4].preview! : media[4].path!" [width]="media[4].width" [height]="media[4].height" [sizes]="'100vw'" [alt]="media[4].filename" priority/>
        </div>
      </div>

    </div>
  </ng-container>

  <!-- More than five media -->
  <ng-container *ngIf="media.length > 5">
    <div class="container-more-images">
      
      <!-- Top Row (Two Images, 60% Height) -->
      <div class="top-row">
        <div class="img-wrapper">
          <img *ngIf="media[0].preview || media[0].path" [ngSrc]="media[0].preview ? media[0].preview! : media[0].path!" [width]="media[0].width" [height]="media[0].height" [sizes]="'100vw'" [alt]="media[0].filename" priority/>
        </div>
        <div class="img-wrapper">
          <img *ngIf="media[1].preview || media[1].path" [ngSrc]="media[1].preview ? media[1].preview! : media[1].path!" [width]="media[1].width" [height]="media[1].height" [sizes]="'100vw'" [alt]="media[1].filename" priority/>
        </div>
      </div>

      <!-- Bottom Row (Three Images, 40% Height) -->
      <div class="bottom-row">
        <div class="img-wrapper">
          <img *ngIf="media[2].preview || media[2].path" [ngSrc]="media[2].preview ? media[2].preview! : media[2].path!" [width]="media[2].width" [height]="media[2].height" [sizes]="'100vw'" [alt]="media[2].filename" priority/>
        </div>
        <div class="img-wrapper">
          <img *ngIf="media[3].preview || media[3].path" [ngSrc]="media[3].preview ? media[3].preview! : media[3].path!" [width]="media[3].width" [height]="media[3].height" [sizes]="'100vw'" [alt]="media[3].filename" priority/>
        </div>
        <div class="img-wrapper cursor-pointer" [attr.data-overlay]="'+' + (media.length - 5)">
          <img *ngIf="media[4].preview || media[4].path" [ngSrc]="media[4].preview ? media[4].preview! : media[4].path!" [width]="media[4].width" [height]="media[4].height" [sizes]="'100vw'" [alt]="media[4].filename" priority/>
        </div>
      </div>

    </div>
  </ng-container>
</div>
