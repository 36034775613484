import {AfterContentChecked, Component, Input, OnInit} from '@angular/core';
import {NgIf, NgOptimizedImage} from "@angular/common";
import {TYPE} from "../../types/types";
import {VideoSrcDirective} from "../../directives/video-src.directive";

@Component({
  selector: 'app-image-preview',
  standalone: true,
  imports: [
    NgIf,
    NgOptimizedImage,
    VideoSrcDirective
  ],
  templateUrl: './image-preview.component.html',
  styleUrl: './image-preview.component.scss'
})
export class ImagePreviewComponent implements OnInit, AfterContentChecked {

  @Input() images: TYPE.Document[] = [];
  @Input() videos: TYPE.Document[] = [];

  public isFirstTall: boolean = false;
  public media: TYPE.Document[] = [];
  public autoplay: boolean = false;

  constructor() {
  }

  public ngOnInit(): void {
    this.prepare();
  }

  public ngAfterContentChecked(): void {
    //this.prepare();
  }

  private prepare(): void {
    this.media = this.videos.concat(this.images);
    //this.media = this.media.slice(0, 5); // For testing
    if (this.media && this.media[0]) {
      this.isFirstTall = !!(this.media[0].width && this.media[0].height && Number(this.media[0].height) > Number(this.media[0].width));
      this.autoplay = this.media[0].type === 'video' && (this.media[0].size! / 1024 / 1024) < 40;
    }
  }
}
