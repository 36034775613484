import {Directive, ElementRef, Input, OnInit} from '@angular/core';
import {ApiService} from "../services/api.service";
import {UserService} from "../services/user.service";

@Directive({
  selector: '[appVideoSrc]',
  standalone: true
})
export class VideoSrcDirective implements OnInit {

  @Input() fileName: string|undefined = undefined;

  constructor(
    private apiService: ApiService,
    private userService: UserService,
    private el: ElementRef
  ) {
  }

  public ngOnInit(): void {
    this.el.nativeElement.src = this.apiService.getUrl() + this.fileName + '?token=' + this.userService.getStoredUserToken();
  }

}
